export var HotelExtraType;
(function (HotelExtraType) {
    HotelExtraType[HotelExtraType["Transfer"] = 2] = "Transfer";
})(HotelExtraType || (HotelExtraType = {}));
export var HotelPriceIncludesType;
(function (HotelPriceIncludesType) {
    HotelPriceIncludesType["BOARD_BASIS"] = "boardbasis";
    HotelPriceIncludesType["LOCAL_CHARGES"] = "localcharges";
    HotelPriceIncludesType["CANCELLATION"] = "cancellation";
    HotelPriceIncludesType["MANDATORY_TRANSFER"] = "mandatoryTransfer";
    HotelPriceIncludesType["HYPE_MESSAGES"] = "hypeMessages";
    HotelPriceIncludesType["STAY_COUNT"] = "stayCount,";
    HotelPriceIncludesType["NIGHTS_COUNT"] = "nightsCount";
    HotelPriceIncludesType["RETURN_FLIGHTS"] = "return_flights";
})(HotelPriceIncludesType || (HotelPriceIncludesType = {}));
export const FunnelQueryParamsEnum = {
    TRIP_STATE_ID: 'tripId',
    CHECK_IN_DATE: 'checkIn',
    CHECK_OUT_DATE: 'checkOut',
    DESTINATION_ID: 'destId',
    HOTEL_ID: 'hotelId',
    DESTINATION_IDS: 'destIds',
    DESTINATIONS: 'destinations',
    HOTEL_IDS: 'hotelIds',
    ROOMS: 'rooms',
    ADULTS: 'adults',
    CHILD_AGES: 'childAges',
    DEPARTURE_AIRPORTS: 'depAirports',
    TRIP_TYPE: 'tripType',
    CABIN: 'cabin',
    DURATION: 'duration',
    FLEXIBLE_DAYS: 'flexibleDays',
    DATE_SEARCH_TYPE: 'dateSearchType',
    LOWER: 'lower',
    UPPER: 'upper',
    HOTELFILTERS: 'hotelFilters',
    FLIGHTFILTERS: 'flightFilters',
    SORT: 'sort',
    FSORT: 'fsort',
    FLIGHT_DETAILS_ID: 'flightDetailsId',
    AIRPORT: 'airport',
    RESORT_ID: 'resortId',
    RESORT_TITLE: 'resortTitle',
    DEEP_LINK_PRICE_TYPE: 'deepLinkPriceType',
    ARRIVAL_AIRPORT: 'arrivalAirport',
    FLIGHT_JOURNEY_TYPE: 'flightJourneyType',
    FLIGHT_ID: 'flightId',
    LINK_ID: 'linkid',
    QUEUE_ID: 'queueId',
    QUOTE_ID: 'quoteId',
    QUO: 'QUO',
    TYPE: 'destType',
    PICK_UP_POINT: 'pickUpPoint',
    DROP_OFF_POINT: 'dropOffPoint',
    BOARD_OPTION_ID: 'boardOptionId',
    PRICE_DISPLAY_MODE: 'priceDisplayMode',
};
export const QueryParamsEnum = Object.assign(Object.assign({}, FunnelQueryParamsEnum), { ACCOUNT_REDIRECT: 'accountRedirect', ACCOUNT_DEEPLINK_REDIRECT: 'accountDeeplinkRedirect', AIRPORT_TITLE: 'airportTitle', FEATURE_FLAGS: 'featureFlags', EMAIL_ID: 'emailid', USER_ID: 'userid', MOCK_ERROR_RETRY_ATTEMPTS: 'mockErrorRetryAttempts', SOURCE: 'source' });
export var URLParamsEnum;
(function (URLParamsEnum) {
    URLParamsEnum["BOOKING_ID"] = "bookingId";
    URLParamsEnum["ID_STRING"] = "idString";
    URLParamsEnum["SLUG"] = "slug";
    URLParamsEnum["BOOKED_TRIP_ID"] = "bookedTripId";
    URLParamsEnum["CRS_ID"] = "csrId";
    URLParamsEnum["ESTAB_ID"] = "estabId";
    URLParamsEnum["AMOUNT"] = "amount";
    URLParamsEnum["PAYMENTMESSAGE"] = "paymentMessage";
})(URLParamsEnum || (URLParamsEnum = {}));
export var PaymentPlanEnum;
(function (PaymentPlanEnum) {
    PaymentPlanEnum[PaymentPlanEnum["Deposit"] = 1] = "Deposit";
    PaymentPlanEnum[PaymentPlanEnum["FullAmount"] = 2] = "FullAmount";
    PaymentPlanEnum[PaymentPlanEnum["Monthly"] = 3] = "Monthly";
})(PaymentPlanEnum || (PaymentPlanEnum = {}));
export var FeatureFlagEnum;
(function (FeatureFlagEnum) {
    FeatureFlagEnum["TEST_FEATURE"] = "TestFeature";
    FeatureFlagEnum["HOTEL_ONLY_TRANSFERS"] = "HotelOnlyTransfers";
    FeatureFlagEnum["FLIGHT_FUNNEL"] = "FlightFunnel";
    FeatureFlagEnum["TEXTBOX_PRICE_FILTER"] = "Textbox_price_filter";
    FeatureFlagEnum["PRICING_CALENDAR"] = "PricingCalendar";
    FeatureFlagEnum["GOOGLE_ADDRESS"] = "GoogleAddress";
    FeatureFlagEnum["ESTAB_LANDING_PRICING_CALENDAR"] = "EstabLandingPriceCalendar";
    FeatureFlagEnum["COMPLAINT_FORM"] = "ComplaintForm";
    FeatureFlagEnum["BETA_ESTAB_PAGE"] = "BetaEstabPage";
    FeatureFlagEnum["SHORTLIST"] = "Shortlist";
    FeatureFlagEnum["OLD_ESTAB_PAGE"] = "OldEstabPage";
})(FeatureFlagEnum || (FeatureFlagEnum = {}));
export var AnywhereSearchResultEnum;
(function (AnywhereSearchResultEnum) {
    AnywhereSearchResultEnum[AnywhereSearchResultEnum["ANYWHERE_ID"] = 1] = "ANYWHERE_ID";
    AnywhereSearchResultEnum["TITLE"] = "Anywhere";
    AnywhereSearchResultEnum[AnywhereSearchResultEnum["TYPE"] = 13] = "TYPE";
})(AnywhereSearchResultEnum || (AnywhereSearchResultEnum = {}));
export var PriceType;
(function (PriceType) {
    PriceType["Total"] = "TOTAL";
    PriceType["PerPerson"] = "PERPERSON";
    PriceType["PerNight"] = "PERNIGHT";
})(PriceType || (PriceType = {}));
export var TenantId;
(function (TenantId) {
    TenantId[TenantId["TR"] = 1] = "TR";
})(TenantId || (TenantId = {}));
export var Cabin;
(function (Cabin) {
    Cabin[Cabin["NotSpecified"] = 0] = "NotSpecified";
    Cabin[Cabin["Economy"] = 1] = "Economy";
    Cabin[Cabin["PremiumEconomy"] = 2] = "PremiumEconomy";
    Cabin[Cabin["First"] = 4] = "First";
    Cabin[Cabin["Business"] = 8] = "Business";
})(Cabin || (Cabin = {}));
export var FilterCategoryEnum;
(function (FilterCategoryEnum) {
    FilterCategoryEnum["BoardType"] = "boardTypes";
    FilterCategoryEnum["ChildDestination"] = "childDestinations";
    FilterCategoryEnum["GeneralSaleDeal"] = "generalSaleDealsOnly";
    FilterCategoryEnum["HolidayType"] = "holidayTypes";
    FilterCategoryEnum["HotelName"] = "hotelName";
    FilterCategoryEnum["PopertyType"] = "propertyTypes";
    FilterCategoryEnum["PriceRange"] = "priceRange";
    FilterCategoryEnum["PropertyAmenity"] = "propertyAmenities";
    FilterCategoryEnum["StarRating"] = "stars";
    FilterCategoryEnum["TripAdvisorRating"] = "tripAdvisorRatings";
})(FilterCategoryEnum || (FilterCategoryEnum = {}));
export var AttributeGroupEnum;
(function (AttributeGroupEnum) {
    AttributeGroupEnum[AttributeGroupEnum["Facilities"] = 1] = "Facilities";
    AttributeGroupEnum[AttributeGroupEnum["HolidayType"] = 2] = "HolidayType";
})(AttributeGroupEnum || (AttributeGroupEnum = {}));
export var FilterProductEnum;
(function (FilterProductEnum) {
    FilterProductEnum["HOTEL"] = "hotel";
    FilterProductEnum["FLIGHT"] = "flight";
})(FilterProductEnum || (FilterProductEnum = {}));
export var HotelCostBreakDownType;
(function (HotelCostBreakDownType) {
    HotelCostBreakDownType[HotelCostBreakDownType["Transfers"] = 23] = "Transfers";
})(HotelCostBreakDownType || (HotelCostBreakDownType = {}));
export var LocalStorageEnum;
(function (LocalStorageEnum) {
    LocalStorageEnum["PRICE_DISPLAY_MODE"] = "priceDisplayMode";
})(LocalStorageEnum || (LocalStorageEnum = {}));
